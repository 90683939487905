import { LMap, LTileLayer, LMarker, LIcon, LPopup } from "vue2-leaflet";
import  L  from "leaflet";
import mapboxgl from "mapbox-gl";
import "mapbox-gl-leaflet";
import axios from "axios";
import InfiniteLoading from 'vue-infinite-loading';

window.mapboxgl = mapboxgl;


Vue.component('Stores', {
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LIcon,
      LPopup,
      InfiniteLoading
    },
    props: [
        
    ],
    data() {
      return {
        q: null,
        stores: [],
        map: {
          loaded: false,
          zoom: 12,
          center: L.latLng(41.8719, 12.5674), // Italy
          url: "https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png",
          tileLayerClass: (url, options) => L.mapboxGL(options),
          layerOptions: {
            accessToken: "pk.eyJ1IjoiZ3djd29ybGQiLCJhIjoiY2ttM2MzOTBlNGV6bDJvbndwa3cwaHlyeSJ9.c5Lv9XabEjt2_XUqIJ8kMA",
            style: "mapbox://styles/mapbox/streets-v11",
          },
          mapOptions: {
            zoomSnap: 0.5,
          },
        },
        icon: L.icon({
          iconUrl: "../../../img/icons/map-pin.svg",
          iconSize: [32, 37],
          iconAnchor: [16, 37]
        }),
        page: 1
      };
    },
    beforeMount() {
      this.search();
    },
    computed: {
      results() {
        return this.stores;
      }
    },
    methods: {
      fitMarkerBounds() {
        let markers = this.stores.map((store) => store.latLng);
        if (markers.length) {
          let bounds = new L.LatLngBounds(markers);
          this.$refs.map.mapObject.flyToBounds(bounds);
        }
      },
      search($state, reset = false) {
        let q = this.q;
        let coordinates = window.user && window.user.coordinates;
        let page = this.page;
        let params = {
          page: reset ? 1 : page
        };
      

        if (q) {
          params.q = q;
        }

        if (coordinates) {
          params.lat = coordinates.latitude;
          params.lon = coordinates.longitude;
        }

        axios.get('/api/stores', { params })
        .then(response => {
          if(response.data.current_page == response.data.last_page){
            $state && $state.complete();
          }
          else{
            let stores = response.data.data.map(store => ({
              id: store.id,
              name: store.name,
              address: store.address,
              latLng: L.latLng(store.latitude, store.longitude)
            }));
            
            if (reset) {
              this.$set(this, 'stores', stores);
            } else {
              this.stores.push(...stores);
            }

            this.$nextTick(() => this.fitMarkerBounds());
            this.page = page + 1;
            $state && $state.loaded();
          }
        });
      },
      onMapReady() {
        this.map.loaded = true;
        this.fitMarkerBounds();
      },
    }
});

