import InfiniteLoading from "vue-infinite-loading";

Vue.component("RecipesSearch", {
    components: {
        InfiniteLoading
    },
    props: [
        "categoriesRecipes",
        "categoriesProducts",
        "difficulties",
        "durations"
    ],
    data() {
        return {
            recipes: [],
            page: 1,
            form: {
                name: null,
                id: null,
                productCategories: null,
                recipeCategory: null,
                duration: null,
                difficulty: null
            }
        };
    },
    methods: {
        filterChange(value, key) {
            console.log(value, key);
            let currVal = this.form[key];
            if (currVal == value) {
                this.$set(this.form, key, null);
            }
            console.log("fc", value, key, this.form[key]);
        },
        setFavorite(recipe, favorite) {
            if (!$("body").data("authenticated")) {
                $('[data-action="login"]')
                    .first()
                    .trigger("click");
            } else {
                this.$set(recipe, "favorite", favorite);
                axios
                    .post(`/api/recipe-favorite`, {
                        id: recipe.id,
                        favorite
                    })
                    .then(response => console.log(response));
            }
        },
        async getRecipes(params = {}, paginate = false) {
            const response = await axios.get("/api/recipes", {
                params: {
                    ...params,
                    locale: document.documentElement.lang
                }
            });
            console.log("getRecipes called.", paginate);
            if (paginate) {
                this.recipes.push(...response.data.data);
            } else {
                this.$set(this, "recipes", response.data.data);
            }
            return response;
        },
        async scrollHandler($state) {
            this.$set(this, "page", this.page + 1);
            let params = this.prepareParams(this.form);
            let response = await this.getRecipes(params, true);

            if (response.data.next_page_url) {
                $state.loaded();
            } else {
                $state.complete();
            }
        },
        prepareParams(data) {
            let params = {};

            if (data.name) {
                // params["name[like]"] = "%" + data.name + "%";
                params["name"] = data.name;
            }

            if (data.recipeCategory) {
                params["category[id]"] = [data.recipeCategory];
            }

            if (data.productCategories) {
                params["products[category_id]"] = data.productCategories;
            }

            if (data.duration) {
                params["preparation_time"] = data.duration;
            }

            if (data.difficulty) {
                params["difficulty_level"] = data.difficulty;
            }

            params["page"] = this.page;

            return params;
        }
    },
    beforeMount() {
        this.getRecipes();
    },
    watch: {
        form: {
            deep: true,
            handler(form) {
                this.page = 1;
                let params = this.prepareParams(form);
                this.getRecipes(params);
            }
        }
    }
});
