const {
    default: axios
} = require("axios");
const {
    default: Swal
} = require("sweetalert2");

Vue.component('UserProfile', {
    props: [
        'user'
    ],
    data() {
        return {
            mutableUser: this.user,
            edit: false
        };
    },
    methods: {
        updateUser(mutableUser) {
            axios
                .put('/api/users/' + mutableUser.id, mutableUser)
                .then(response => {
                    console.log('update user response', response);
                    this.edit = false;
                    Swal.fire({
                        icon: "success",
                        text: response.data.message,
                        timer: 3000,
                        timerProgressBar: true,
                    }).then(_ => {
                        window.location.reload();
                    });

                })
                .catch(error => {
                    console.log(error);
                    let response = error.response;
                    let html = [];
                    if (response.status === 422) {
                        let fields = response.data.errors;
                        let fieldMap = Object.entries(fields);

                        for (const [key, value] of fieldMap) {
                            html.push(value[0]);

                        }
                    } else {
                        console.error('Error', error);
                        html.push(response.data.message);
                    }
                    Swal.fire({
                        icon: "error",
                        html: html.join("<br />"),
                        timer: 3000,
                        timerProgressBar: true,

                    });
                });
        }
    }
});
