Vue.component("RecipeCard", {
    template: `
    <div class="recepie-card-wrapper h-100">
      <a href="#!" class="add-to-fav" @click="$emit('favorite', !favorite)">
          <i class="fa-heart" :class="[ favorite ? 'fas' : 'far' ]"></i>
      </a>
      <a :href="'/ricette/' + slug">
        <div class="img-wrapper">
            <img :src="thumbnail" :alt="name" />
        </div>
        <div class="d-flex flex-column justify-content-between">
            <h3>{{ category.name }}</h3>
            <p>{{ name }}</p>
            <ul class="list-inline">
                <li class="list-inline-item time">{{ preparation_time }}</li>
                <li class="list-inline-item difficulty">{{ difficulty_level }}</li>
                <li class="list-inline-item forPeople">{{ persons }}</li>
            </ul>
        </div>
      </a>
  </div>
  `,
    props: [
        "category",
        "name",
        "id",
        "thumbnail",
        "preparation_time",
        "difficulty_level",
        "persons",
        "favorite",
        "slug"
    ]
});
