import Select2 from 'v-select2-component';

Vue.component('async-select2', {
  components: {
    Select2
  },
  template: `
    <Select2
      v-model="mutableValue"
      :options="mutableOptions"
      :settings="config"
    />
  `,
  props: ['options', 'endpoint', 'value'],
  data() {
    return {
      mutableValue: this.value,
      mutableOptions: this.options,
      config: {
        ajax: {
          url: this.endpoint,
          delay: 250,
          data: function (params) {
            let query = {
              search: params.term,
              page: params.page || 1,
              type: 'select2'
            }

            // Query parameters will be ?search=[term]&page=[page]
            return query;
          }
        }
      }
    }
  },
  watch: {
    mutableValue(value) {
      this.$emit('input', value);
    }
  }
});