Vue.component("RecipesListing", {
    props: {
        recipes: {
            type: Array,
            required: true,
            default: () => []
        },
        categories: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            recipeItems: 3,
            categorySliderOptions: {
                slidesToShow: 3,
                slidesToScroll: 1,
                adaptiveHeight: false,
                centerMode: true,
                dots: false,
                arrows: false,
                infinite: false,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            },
            mutableRecipes: this.recipes,
            filters: {}
        };
    },
    methods: {
        loadMore() {
            this.$set(this, "recipeItems", this.recipeItems + 5);
        },
        applyFilter(param, payload) {
            let filters = this.filters;

            if (filters.hasOwnProperty(param) && filters[param] === payload) {
                this.$delete(this.filters, param);
            } else {
                this.$set(this.filters, param, payload);
            }

            if ($(window).width() > 768) {
                this.reInit();
            }
        },
        setFavorite(recipe, favorite) {
            if (!$("body").data("authenticated")) {
                $('[data-action="login"]')
                    .first()
                    .trigger("click");
            } else {
                this.$set(recipe, "favorite", favorite);
                axios
                    .post(`/api/recipe-favorite`, {
                        id: recipe.id,
                        favorite
                    })
                    .then(response => console.log(response));
            }
        },
        reInit() {
            this.$refs.slick.destroy();
            this.$nextTick(() => {
                this.$refs.slick.create();
            });
        }
    },
    computed: {
        isPaginable() {
            return window.innerWidth <= 768;
        },
        filteredRecipes() {
            let filters = this.filters;

            let recipes = this.mutableRecipes.filter(recipe => {
                // categories filter
                if (filters.category) {
                    if (recipe.category_id !== filters.category) {
                        return false;
                    }
                }

                // todo: difficulty, persons etc.

                return true;
            });

            recipes = this.isPaginable
                ? recipes.slice(0, this.recipeItems)
                : recipes;
            return recipes;
        },
        recipeSlidesToShow() {
            let visibleRecipes = this.filteredRecipes;
            return visibleRecipes.length > 3 ? 3.68 : visibleRecipes.length;
        },
        recipeSliderOptions() {
            return {
                slidesToShow: this.recipeSlidesToShow,
                slidesToScroll: 1,
                adaptiveHeight: false,
                centerMode: true,
                dots: false,
                arrows: true,
                prevArrow: `
                <a class="slick-prev slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 83 83">
                <g id="prevSlide" transform="translate(381 -349)">
                  <circle id="Ellipse_38" data-name="Ellipse 38" cx="41.5" cy="41.5" r="41.5" transform="translate(-381 349)" fill="#f50c1a"></circle>
                  <path id="Union_25" data-name="Union 25" d="M.73,17.5,0,18.239.73,17.5,0,16.761l.73.739L18,0,.73,17.5,18,35Z" transform="translate(-348.5 372.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"></path>
                </g>
                </svg></a>
                `,
                nextArrow: `
                <a class="slick-next slick-arrow"><svg id="nextSlide" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 83 83">
                <circle id="Ellipse_38" data-name="Ellipse 38" cx="41.5" cy="41.5" r="41.5" fill="#f50c1a"></circle>
                <path id="Union_25" data-name="Union 25" d="M.73,17.5,0,16.761l.73.739L0,18.239.73,17.5,18,35,.73,17.5,18,0Z" transform="translate(50.5 58.5) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"></path>
                </svg>
                </a>
                `,
                responsive: [
                    {
                        breakpoint: 769,
                        settings: "unslick"
                    }
                ]
            };
        }
    }
});
