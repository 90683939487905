const {
    default: axios
} = require("axios");
const {
    default: Swal
} = require("sweetalert2");

Vue.component('RegisterWizard', {
    props: [
        'steps',
        'nations',
        'provinces'
    ],
    data() {
        return {
            mutableSteps: this.steps,
            form: {
                first_name: null,
                last_name: null,
                email: null,
                email_confirmation: null,
                password: null,
                password_confirmation: null,
                newsletter: false,
                nation_id: null,
                province_id: null,
                city_id: null,
                address: null,
                phone: null,
                postal_code: null,
                refered_by: null
            },
        };
    },
    methods: {
        next() {

            var passRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");


            if (!passRegex.test(this.form.password) || !passRegex.test(this.form.password_confirmation)) {
                Swal.fire({
                    icon: "error",
                    text: "Il formato della password non è valido",
                    timer: 3000,
                    timerProgressBar: true,

                });
                let nextIndex = this.activeStepIndex + 1;
                let currentStep = this.mutableSteps[this.activeStepIndex];
                let nextStep = this.mutableSteps[nextIndex];
                this.$set(currentStep, 'active', true);
                this.$set(currentStep, 'done', false);
                this.$set(nextStep, 'active', false);
                return;
            }

          

            if (this.form.email !== this.form.email_confirmation) {
                   Swal.fire({
                        icon: "error",
                        text: "Mancata corrispondenza e-mail",
                        timer: 3000,
                        timerProgressBar: true,

                    });
                    let nextIndex = this.activeStepIndex + 1;
                    let currentStep = this.mutableSteps[this.activeStepIndex];
                    let nextStep = this.mutableSteps[nextIndex];
                    this.$set(currentStep, 'active', true);
                    this.$set(currentStep, 'done', false);
                    this.$set(nextStep, 'active', false);
                    return;
            }

            if (this.form.password !== this.form.password_confirmation) {
                Swal.fire({
                     icon: "error",
                     text: "Mancata corrispondenza password",
                     timer: 3000,
                     timerProgressBar: true,

                 });
                 let nextIndex = this.activeStepIndex + 1;
                 let currentStep = this.mutableSteps[this.activeStepIndex];
                 let nextStep = this.mutableSteps[nextIndex];
                 this.$set(currentStep, 'active', true);
                 this.$set(currentStep, 'done', false);
                 this.$set(nextStep, 'active', false);
                 return;
            }

            let nextIndex = this.activeStepIndex + 1;
            let currentStep = this.mutableSteps[this.activeStepIndex];
            let nextStep = this.mutableSteps[nextIndex];

            this.$set(currentStep, 'active', false);
            this.$set(currentStep, 'done', true);
            this.$set(nextStep, 'active', true);
        },
        prev() {
            let prevIndex = this.activeStepIndex - 1 > 0 ? this.activeStepIndex - 1 : 0;
            let currentStep = this.mutableSteps[this.activeStepIndex];
            let prevStep = this.mutableSteps[prevIndex];

            this.$set(currentStep, 'active', false);
            this.$set(currentStep, 'done', true);
            this.$set(prevStep, 'active', true);
        },
        setStep(step) {
            if (!step.done || step.active) {
                return false;
            }
            let currentStep = this.mutableSteps[this.activeStepIndex];
            this.$set(currentStep, 'active', false);
            this.$set(step, 'active', true);
        },
        signUp(form) {
            axios
                .post('/api/users', form)
                .then(response => {
                    this.$refs.loginForm.submit();
                    this.next();
                })
                .catch(error => {
                    let response = error.response;
                    let html = [];
                    if (response.status === 422) {
                        let fields = response.data.errors;
                        let fieldMap = Object.entries(fields);

                        for (const [key, value] of fieldMap) {
                            html.push(value[0]);

                        }
                    } else {
                        console.error('Error', error);
                        html.push(response.data.message);
                    }
                    Swal.fire({
                        icon: "error",
                        html: html.join("<br />"),
                        timer: 3000,
                        timerProgressBar: true,

                    });
                });
        }
    },
    computed: {
        activeStep() {
            return this.mutableSteps.find(step => step.active);
        },
        activeStepIndex() {
            return this.mutableSteps.findIndex(step => step.active);
        },
    }
});
